import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL;

class EvidencesService {
  getEvidences(
    client,
    {
      reference = null,
      claimType = null,
      timestamp = null,
      registration = null,
    }
  ) {
    const params = new URLSearchParams({ client_id: client });
    if (reference !== null) params.append("reference", reference);
    return axios.get(
      `https://api.varsanpr.com/api/claims/evidence?` + params.toString(),
      { headers: authHeader() }
    ); //authHeader() returns an object with the authentication string
  }

  getClaimData(id, client) {
    return axios.get(
      `https://api.varsanpr.com/api/claims/evidence/${id}?client_id=${client}`,
      { headers: authHeader() }
    );
  }

  uploadVehicleImage(id, data) {
    return axios.put(
      `https://api.varsanpr.com/api/claims/evidence/${id}`,
      data,
      { headers: authHeader() }
    );
  }

  uploadTillReceipt(id, data) {
    return axios.put(
      `https://api.varsanpr.com/api/claims/evidence/${id}`,
      data,
      { headers: authHeader() }
    );
  }

  cancelClaim(reason, client, id) {
    return axios.patch(
      `https://api.varsanpr.com/api/claims/${id}/changestatus`,
      { reason: reason, client_id: client, status: "Cancelled" },
      { headers: authHeader() }
    );
  }

  allowProcessing(id, client) {
    return axios.patch(
      `https://api.varsanpr.com/api/claims/${id}/changestatus`,
      { client_id: client, status: "Pending" },
      { headers: authHeader() }
    );
  }
}

export default new EvidencesService();
