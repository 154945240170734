export default {
  "languages": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglese"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])}
  },
  "global": {
    "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossimamente"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzionale"])},
    "driveoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guida off"])},
    "escalated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drive Off (No Means of Payment)"])},
    "nmop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun mezzo di pagamento"])},
    "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcheggio"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitare..."])},
    "blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libro nero"])},
    "unblacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lista nera"])},
    "make": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare"])},
    "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello"])},
    "colour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colore"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togliere"])},
    "select_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un file"])},
    "clear_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro Pulito"])}
  },
  "navigation": {
    "top": {
      "applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicazioni"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
    },
    "side": {
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cruscotto"])},
      "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processo"])},
      "face": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viso"])},
      "faces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facce"])},
      "incidents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incidenti"])},
      "face_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persone"])},
      "new_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo reclamo"])},
      "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvenimenti"])},
      "blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libro nero"])},
      "sites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siti"])},
      "claims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crediti"])},
      "view_sites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza siti"])},
      "new_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo sito"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppi"])},
      "new_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo Gruppo"])},
      "whitelists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste bianche"])},
      "client_whitelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whitelist dei clienti"])},
      "global_whitelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whitelist globale"])},
      "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestire i reclami"])}
    }
  },
  "login": {
    "auth_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi al tuo account e continua con la dashboard."])},
    "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola d’ordine"])},
    "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
    "errors": {
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome utente è obbligatorio!"])},
      "passowrd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password è obbligatoria!"])}
    }
  },
  "dashboard": {
    "todays-payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I pagamenti di oggi"])},
    "todays-losses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le perdite di oggi"])},
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieri"])},
    "worst_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sito peggiore"])},
    "lost_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perso oggi"])},
    "worst_pump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pompa peggiore"])},
    "fuel_recovery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupero carburante"])},
    "this_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quest’anno"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica"])},
    "active_parking_sites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcheggi attivi"])},
    "being_recovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essere recuperati"])},
    "chart_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le statistiche riportate di seguito riflettono le perdite correnti in fase di elaborazione e recupero."])},
    "money_recovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denaro recuperato"])},
    "recovered_on_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperato in loco"])},
    "losses_reported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdite segnalate"])}
  },
  "pagination": {
    "showing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visualizzazione ", _interpolate(_named("mostrando")), " di ", _interpolate(_named("to")), " di ", _interpolate(_named("total")), " voci"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precedente"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossimo"])}
  },
  "claims": {
    "processing": {
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cercare"])},
      "vehicle-registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione del veicolo"])},
      "reference-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione del veicolo"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutto"])},
      "driveoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drive Off"])},
      "nmop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Means of Payment"])},
      "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking"])},
      "actionable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actionable"])},
      "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting"])},
      "nonactionable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-Actionable"])},
      "hide-locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Locked"])},
      "received-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["received on"])},
      "progress-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["progress-message"])}
    },
    "new": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un reclamo"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il tipo di reclamo che desideri creare."])},
      "nmop": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun mezzo di pagamento"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un cliente ha erogato carburante e non è in grado di effettuare il pagamento in loco."])}
      },
      "driveoff": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guida off"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un cliente ha erogato carburante e poi se ne è andato senza fare un tentativo o rifiutarsi di pagare il carburante."])}
      },
      "abandoned": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veicolo abbandonato"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un veicolo è stato lasciato abbandonato dal tuo sito per un lungo periodo di tempo"])}
      },
      "nameAndAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome e indirizzo del cliente"])},
      "vehicleRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immatricolazione del veicolo"])},
      "fuelValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore del carburante in £"])},
      "litres_of_fuel_dispensed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litri di carburante erogati"])},
      "pumpNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero pompa"])},
      "tillReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia della ricevuta di cassa"])},
      "vehicleImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagine del veicolo"])},
      "cctv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filmati CCTV"])},
      "vehicleDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione del veicolo"])},
      "vehicleDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giorni abbandonati"])},
      "createNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea ora"])},
      "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sito"])},
      "select_a_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un sito"])},
      "site_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un sito"])},
      "registration_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se non conosci la registrazione del veicolo, non saremo in grado di recuperare il carburante per te."])},
      "vehicle_make": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marca del veicolo"])},
      "vehicle_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello del veicolo"])},
      "vehicle_colour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colore del veicolo"])},
      "find_make_model_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trova marca, modello e colore del veicolo"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dattero"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ore"])},
      "type_of_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di carburante"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore"])},
      "litres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litri"])},
      "pump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pompa"])},
      "select_a_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un tipo"])},
      "petrol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benzina"])},
      "diesel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesel"])},
      "adblue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AdBlue"])},
      "add_new_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere Nuovo Carburante"])},
      "liquid_petroleum_gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas di petrolio liquefatto (GPL)"])},
      "fuel_type_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di fornire un tipo valido per il carburante."])},
      "fuel_value_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di fornire un valore valido per il carburante."])},
      "fuel_litres_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di fornire un litro valido per il carburante."])},
      "fuel_pump_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di fornire una pompa valida per il carburante."])},
      "fuel_type_minimum_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario fornire almeno 1 tipo di carburante."])},
      "very_important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molto importante"])},
      "name_and_address_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se non sei in grado di fornirci il nome completo e l'indirizzo del conducente, non siamo in grado di elaborarlo come nessun mezzo di pagamento e verrà convertito in un Drive Off completo dal nostro team di amministrazione."])},
      "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del cliente"])},
      "customer_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail del cliente"])},
      "customer_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile del cliente"])},
      "customer_postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice postale del cliente"])},
      "locate_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individua indirizzo"])},
      "select_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Indirizzo"])},
      "select_an_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un indirizzo"])},
      "address_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo 1"])},
      "address_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo 2"])},
      "address_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo 3"])},
      "address_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo 4"])},
      "important_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avviso importante"])},
      "upload_as_much_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurati di caricare quante più prove possibile a sostegno dell'affermazione. Avere più prove disponibili aumenta le nostre possibilità di recuperare il carburante e riduce la necessità di contattarti per ulteriori informazioni in futuro."])},
      "columns_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono necessarie colonne contrassegnate con <span class='text-danger'>*</span>"])},
      "evidence_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario fornire un minimo di 1 immagine del veicolo e 1 ricevuta di cassa"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
      "evidence_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di prova"])},
      "comments_on_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenti sulle prove"])},
      "drive_off_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drive Off Report"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
      "valid_evidence_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un tipo di prova valido."])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenti"])},
      "please_provide_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di fornire una descrizione del No Means of Payment. Includi quante più informazioni possibile, ad esempio:"])},
      "filling_cannister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente stava riempiendo un contenitore?"])},
      "attempt_to_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente ha fatto qualche tentativo di pagare?"])},
      "please_provide_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di fornire quante più informazioni possibile sull'evento"])},
      "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare"])},
      "error_with_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è un problema con le informazioni fornite. Per ulteriori informazioni, consulta i messaggi di errore di cui sopra."])},
      "submitting_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invio della richiesta"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successo"])},
      "claim_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attestazione creata correttamente. Il numero di riferimento è"])},
      "min_3_chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve essere di almeno 3 caratteri!"])},
      "max_12_chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non deve essere più di 12 caratteri!"])},
      "must_be_valid_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve essere tempo valido formattato come: "])},
      "creating_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione di una nuova attestazione..."])},
      "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento"])},
      "evidence_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file di prove"])},
      "file_format_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è tentato di caricare un tipo di file non valido. Si prega di fornire uno di PNG, JPEG o PDF."])},
      "file_size_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possiamo accettare solo immagini di dimensioni inferiori a 25 MB!"])}
    }
  },
  "manage": {
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creata"])},
    "manage_claims_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "events": {
    "filters": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
      "click_to_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicca per aprire"])},
      "vehicle_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immatricolazione del veicolo"])},
      "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sito"])},
      "select_a_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un sito"])},
      "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macchina fotografica"])},
      "select_a_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona una fotocamera"])},
      "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direzione"])},
      "select_a_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona una direzione"])},
      "leaving_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lasciare il sito"])},
      "entering_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserimento nel sito"])},
      "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di inizio"])},
      "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di fine"])},
      "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora di inizio"])},
      "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora di fine"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca"])}
    },
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvenimenti"])},
    "blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libro nero"])},
    "unblacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lista nera"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista"])},
    "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passeggiata"])},
    "event_close": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Premi ", _interpolate(_list(0)), " o fai clic all'esterno dell'immagine per chiudere questo popup!"])}
  },
  "event": {
    "blacklist_entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voci della lista nera"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Società"])},
    "reason_for_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo della lista nera"])},
    "date_blacklisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data nella lista nera"])},
    "claims_outstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reclami in sospeso"])},
    "customer_service_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio del servizio clienti"])},
    "important_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avviso importante"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se questo veicolo è nella lista nera, il messaggio del servizio clienti non verrà visualizzato sul display VARS."])},
    "drive_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guida off"])},
    "no_means_of_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun mezzo di pagamento"])},
    "fly_tipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ribaltamento della mosca"])},
    "theft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Furto"])},
    "aggressive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggressivo"])},
    "plate_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mancata corrispondenza della piastra"])},
    "vars_test_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veicolo di prova VARS"])}
  },
  "vehicle_notes": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note sul veicolo"])},
    "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["su"])},
    "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])}
  },
  "sites": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAP"])}
  }
}